import clsx from 'clsx'
import React, { ReactElement } from 'react'
import { Button as SemanticButton, ButtonProps } from 'semantic-ui-react'

import styles from './Button.module.scss'

type Props = Omit<ButtonProps, 'style'> & {
  size?: 'small' | 'medium' | 'large'
  style?: 'primary' | 'secondary' | 'primaryOutline' | 'primaryGhost' | 'disabled'
  onClick?: () => void
  isLoading?: boolean
}

const Button = ({
  onClick,
  isLoading,
  disabled,
  children,
  size = 'medium',
  style = 'primary',
  className: classNameProp,
  ...props
}: Props): ReactElement => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.stopPropagation()

    if (onClick && !disabled && !isLoading) {
      onClick()
    }
  }

  const className = clsx(
    styles.button,
    {
      [styles.primary]: style === 'primary',
      [styles.secondary]: style === 'secondary',
      [styles.primaryOutline]: style === 'primaryOutline',
      [styles.primaryGhost]: style === 'primaryGhost',
      [styles.disabled]: style === 'disabled',
      [styles.small]: size === 'small',
      [styles.medium]: size === 'medium',
    },
    classNameProp
  )

  return (
    <SemanticButton
      {...props}
      size={size}
      onClick={handleClick}
      loading={isLoading}
      disabled={disabled}
      className={className}
    >
      {children}
    </SemanticButton>
  )
}

export default Button
