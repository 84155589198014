import clsx from 'clsx'
import React, { ReactElement, ReactNode, useCallback } from 'react'
import { Form as SemanticForm } from 'semantic-ui-react'
import { FormProps } from 'semantic-ui-react'

import styles from './Form.module.scss'

type Props = FormProps & {
  children?: ReactNode
  onSubmit?: () => void
}

const Form = ({ children, onSubmit, className: classNameProp, ...props }: Props): ReactElement => {
  const className = clsx(styles.form, classNameProp)

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>): void => {
      e.preventDefault()
      onSubmit && onSubmit()
    },
    [onSubmit]
  )

  return (
    <SemanticForm {...props} className={className} onSubmit={handleSubmit}>
      {children}
    </SemanticForm>
  )
}

export default Form
