/* eslint-disable @typescript-eslint/no-explicit-any */

import localforage from 'localforage'
import { createStore, applyMiddleware, compose } from 'redux'
import { persistReducer } from 'redux-persist'
import persistStore from 'redux-persist/es/persistStore'
import thunk from 'redux-thunk'

import rootReducer from './reducers'

const persistConfig = {
  version: 2,
  key: 'root',
  storage: localforage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const enhancer = composeEnhancers(applyMiddleware(thunk))

const store = createStore(persistedReducer, enhancer)
const persistor = persistStore(store)

export { store, persistor }
