import React, { ReactChild } from 'react'

import styles from './SidebarMenu.module.scss'

type Props = {
  children: ReactChild
  title: string
}

const SidebarMenu: React.FC<Props> = ({ children }) => (
  <div className={styles.sidebarContainer}>
    <div className={styles.sidebar}>
      <h1 className={styles.heading}>Internal Portal</h1>
      {children}
    </div>
  </div>
)

export default SidebarMenu
