import React from 'react'
import { withRouter, Redirect, RouteComponentProps } from 'react-router-dom'

import { PublicRoutes } from 'routes/consts'

interface Props extends RouteComponentProps {
  url: string
  fallbackUrl?: string
}

const FallbackRedirect = ({ url, fallbackUrl }: Props): React.ReactElement => {
  if (!url && !fallbackUrl) {
    return <Redirect to={PublicRoutes.ROOT} />
  }
  if (!url) {
    return <Redirect to={fallbackUrl ? fallbackUrl : PublicRoutes.ROOT} />
  }
  return <Redirect to={url} />
}

export default withRouter(FallbackRedirect)
