import { faUserFriends } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { AuthRoutes } from 'routes/consts'
import MenuItem from 'ui/components/SidebarMenu/MenuItem/index'
import SidebarMenu from 'ui/components/SidebarMenu/SidebarMenu'
import { DashboardRouter } from 'ui/pages/dashboard/DashboardRouter'

import styles from './DashboardLayout.module.scss'

const DashboardLayout: React.FC = () => (
  <div className={styles.adminLayout}>
    <div className={styles.leftSidebar}>
      <SidebarMenu title='Internal Portal'>
        <MenuItem
          link={AuthRoutes.CREATE_ORGANIZATION}
          accessoryLeft={<FontAwesomeIcon icon={faUserFriends} />}
          label='Create Organization'
          active
        />
      </SidebarMenu>
    </div>
    <div className={styles.content}>
      <DashboardRouter />
    </div>
  </div>
)

export default DashboardLayout
