import { combineReducers } from 'redux'

import auth from './authReducer'
import * as StateTypes from './types'

export type RootState = {
  language: StateTypes.LanguageState
  auth: StateTypes.AuthState
}

export default combineReducers({
  auth,
})
