import React, { ReactElement } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { Dispatch } from 'redux'
import { Dropdown } from 'semantic-ui-react'
import UserService from 'services/userService'

import { SignOutAction } from 'actions/types'
import { signOut } from 'actions/user'
import { ReactComponent as DefaultAvatar } from 'assets/images/DefaultAvatar.svg'
import { ReactComponent as OrbiseedLogo } from 'assets/images/OrbiseedLogo.svg'
import { RootState } from 'reducers'
import { AuthState } from 'reducers/types'
import { AuthReverseRoutes, PublicRoutes } from 'routes/consts'
import { showErrorToast } from 'utils/toastUtil'

import styles from './Header.module.scss'

type Props = ReduxProps & DispatchProps

const Header = ({ auth, signOut }: Props): ReactElement => {
  const history = useHistory()

  const onSignOut = async (): Promise<void> => {
    try {
      await UserService.signOut({
        identifier: auth.user?.email,
        sessionId: auth.sessionId,
      })

      signOut()

      history.push(AuthReverseRoutes.SIGN_IN)
    } catch {
      showErrorToast({ title: 'An error occurred, please try again.' })
    }
  }

  return (
    <div className={styles.appHeader}>
      <div className={styles.headerLeft}>
        <Link to={PublicRoutes.ROOT}>
          <OrbiseedLogo className={styles.logo} />
        </Link>
      </div>
      <div className={styles.headerRight}>
        {auth.isUserSignedIn ? (
          <div className={styles.userMenuContainer}>
            <Dropdown
              text={auth.user?.name || auth.user?.email}
              className={styles.userMenu}
              icon={<DefaultAvatar className={styles.avatar} />}
            >
              <Dropdown.Menu direction='left' className={styles.userDropdown}>
                <Dropdown.Item text='Sign Out' className={styles.dropdownItem} onClick={onSignOut} />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) : (
          <div className={styles.policyContainer}>
            <Link to='/policy/terms-and-conditions' className={styles.policyLink}>
              Terms of Service
            </Link>
            <Link to='/policy/privacy' className={styles.policyLink}>
              Privacy Policy
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

type ReduxProps = {
  auth: AuthState
}

const mapStateToProps = (state: RootState): ReduxProps => ({
  auth: state.auth,
})

type DispatchProps = {
  signOut: () => SignOutAction
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  signOut: (): SignOutAction => dispatch(signOut()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
