import { Form, Formik } from 'formik'
import React from 'react'
import { connect } from 'react-redux'
import OrganizationService from 'services/organizationService'
import * as yup from 'yup'

import { RootState } from 'reducers'
import { AuthState } from 'reducers/types'
import { FormField, Button, Input } from 'ui/components'
import { showErrorToast, showSuccessToast } from 'utils/toastUtil'

import styles from './CreateOrganizationPage.module.scss'

const CreateOrganizationPage: React.FC<ReduxProps> = ({ auth }) => {
  // eslint-disable-next-line
  const handleSubmit = async (values: any, { setSubmitting, resetForm }: any) => {
    try {
      setSubmitting(true)

      await OrganizationService.create({
        userId: auth.user.id,
        organizationName: values.organizationName,
        rootAdminEmail: values.rootAdminEmail,
      })

      showSuccessToast({ title: 'Successfully created the organization, the admin has been invited via email.' })
      resetForm()
    } catch {
      showErrorToast({ title: 'An error occurred, please try again.' })
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <div className={styles.layout}>
      <Formik
        initialValues={{ subdomain: '', organizationName: '', rootAdminEmail: '' }}
        validationSchema={yup.object().shape({
          organizationName: yup.string().required('organization name is required'),
          rootAdminEmail: yup.string().email('invalid email format').required('root admin email is required'),
        })}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit} className={styles.createOrganizationForm}>
            <h1 className={styles.formTitle}>Create Organization</h1>
            <FormField className={styles.formField}>
              <label className={styles.fieldLabel}>Organization Subdomain</label>
              <Input type='text' name='subdomain' className={styles.fieldInput} isDisabled />
            </FormField>
            <FormField>
              <label className={styles.fieldLabel}>Organization Name (required)</label>
              <Input type='text' name='organizationName' className={styles.fieldInput} />
            </FormField>
            <FormField>
              <label className={styles.fieldLabel}>Organization Admin Email (required)</label>
              <Input type='text' name='rootAdminEmail' className={styles.fieldInput} />
            </FormField>
            <Button type='submit' isLoading={isSubmitting}>
              Create Organization
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

type ReduxProps = {
  auth: AuthState
}

const mapStateToProps = (state: RootState): ReduxProps => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(CreateOrganizationPage)
