import React from 'react'
import { Redirect, Switch } from 'react-router-dom'

import AuthRoute from 'routes/AuthRoute'

import { CreateOrganizationPage } from '../CreateOrganizationPage'

const AdminRouter: React.FC = () => (
  <Switch>
    <Redirect exact path='/dashboard' to='dashboard/create-organization' />
    <AuthRoute exact path='/dashboard/create-organization' component={CreateOrganizationPage} />
  </Switch>
)

export default AdminRouter
