/* eslint-disable no-unused-vars */
export enum CookieTypes {
  SESSION_ID_COOKIE = 'ob_w_s',
  UID_COOKIE = 'ob_w_i',
  ORGANIZATION_COOKIE = 'ob_w_o',
  LANGUAGE_COOKIE = 'ob_l_g_g',
}
export const defaultCookieExpirationDate = 1 / 3

export const readCookie = (name: string): string | null => {
  if (!name) return null

  const nameEQ = name + '='
  const cookies = document.cookie.split(';')
  for (let c of cookies) {
    c = c.trim()
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

export const createCookie = (name: string, value: string, days: number): void => {
  let expires = ''
  const dayInMs = 24 * 60 * 60 * 1000

  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * dayInMs)
    expires = '; expires=' + date.toUTCString()
  }

  document.cookie = name + '=' + value + expires + '; path=/'
}

export const eraseCookie = (name: string): void => {
  createCookie(name, '', -1)
}
