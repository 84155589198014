// For the new microservices architecture

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import axios, { AxiosResponse } from 'axios'

export const postAuthApi = (endpoint: string, body: any, config: any): Promise<AxiosResponse> =>
  axios.post(endpoint, body, config)

export const getAuthApi = (endpoint: string, config: any): Promise<AxiosResponse> => axios.get(endpoint, config)

export const patchAuthApi = (endpoint: string, body: any, config: any): Promise<AxiosResponse> =>
  axios.patch(endpoint, body, config)

export const putAuthApi = (endpoint: string, body: any, config: any): Promise<AxiosResponse> =>
  axios.put(endpoint, body, config)

export const deleteAuthApi = (endpoint: string, config: any): Promise<AxiosResponse> => axios.delete(endpoint, config)

export const postPublicApi = (endpoint: string, body: any, config: any): Promise<AxiosResponse> =>
  axios.post(endpoint, body, config)

export const getPublicApi = (endpoint: string, config: any): Promise<AxiosResponse> => axios.get(endpoint, config)

export const patchPublicApi = (endpoint: string, body: any, config: any): Promise<AxiosResponse> =>
  axios.patch(endpoint, body, config)

export const putPublicApi = (endpoint: string, body: any, config: any): Promise<AxiosResponse> =>
  axios.put(endpoint, body, config)
