import React from 'react'
import { toast } from 'react-toastify'

import ToastContent from 'ui/components/ToastContent'
import { ToastProps } from 'ui/components/ToastContent/types'

const showSuccessToast = ({ title, message }: ToastProps): void => {
  toast(<ToastContent type='success' title={title} message={message} />, { type: 'success' })
}

const showErrorToast = ({ title, message }: ToastProps): void => {
  toast(<ToastContent type='error' title={title} message={message} />, { type: 'error' })
}

export { showSuccessToast, showErrorToast }
