import { isArray } from 'lodash'
import queryString, { ParsedQuery } from 'query-string'

const getQueryParamValue = (location: Location, key: number): string | string[] | null | undefined =>
  queryString.parse(location.search)[key]

const getQueryParams = (location: Location): ParsedQuery<string> => queryString.parse(location.search)

const getFirstValueOfParam = (param: string | string[] | null): string => (isArray(param) ? param[0] : param || '')

export { getQueryParamValue, getQueryParams, getFirstValueOfParam }
