import React, { ReactElement } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { Slide, ToastContainer } from 'react-toastify'
import { PersistGate } from 'redux-persist/integration/react'

import AppRoutes from './routes'
import { persistor, store } from './store'

import './App.scss'

export default (): ReactElement => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <div className='appContent'>
          <ToastContainer
            position='top-center'
            transition={Slide}
            draggable={false}
            autoClose={5000}
            pauseOnFocusLoss
            hideProgressBar
            pauseOnHover
          />
          <AppRoutes />
        </div>
      </Router>
    </PersistGate>
  </Provider>
)
