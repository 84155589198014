import clsx from 'clsx'
import React, { ReactElement } from 'react'

import { ReactComponent as CheckmarkIcon } from 'assets/images/CheckmarkIcon.svg'
import { ReactComponent as ErrorIcon } from 'assets/images/ErrorIcon.svg'

import { ToastProps } from './types'

import styles from './ToastContent.module.scss'

type ToastContentProps = ToastProps & {
  type: 'success' | 'error'
  title?: string
  message?: string
}

const ToastContent = ({ type, title, message }: ToastContentProps): ReactElement => {
  const className = clsx(styles.toast, {
    [styles.success]: type === 'success',
    [styles.error]: type === 'error',
  })

  return (
    <div className={className}>
      <div className={styles.leftAccessory}>
        {type === 'success' && <CheckmarkIcon />}
        {type === 'error' && <ErrorIcon />}
      </div>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div
          className={clsx(styles.message, {
            [styles.messageMarginTop]: message && title,
          })}
        >
          {message}
        </div>
      </div>
    </div>
  )
}

export default ToastContent
