import React, { Fragment, ReactElement } from 'react'
import { Route, Switch } from 'react-router-dom'

import Signin from 'ui/pages/auth/SignIn'
import DashboardLayout from 'ui/pages/common/DashboardLayout'
import Header from 'ui/pages/common/Header'
import HomePage from 'ui/pages/home'

import AuthReverseRoute from './AuthReverseRoute'
import AuthRoute from './AuthRoute'

const WithHeader: React.FC = ({ children }) => (
  <Fragment>
    <Header />
    <div className='pageContainer'>{children}</div>
  </Fragment>
)

const AppRoutes = (): ReactElement => (
  <Switch>
    <Route exact path='/' component={HomePage} />
    <AuthReverseRoute exact path='/signin' component={Signin} />
    <WithHeader>
      <Switch>
        <AuthRoute path='/dashboard' component={DashboardLayout} />
      </Switch>
    </WithHeader>
  </Switch>
)

export default AppRoutes
