import clsx from 'clsx'
import { FieldAttributes, useField } from 'formik'
import React, { ReactElement } from 'react'
import { InputProps } from 'semantic-ui-react'

import InputError from '../InputError'

import styles from './Input.module.scss'

// eslint-disable-next-line
type Props = FieldAttributes<any> &
  InputProps & {
    errorMessage?: string
    isDisabled?: boolean
  }

export default ({ errorMessage, isDisabled = false, className: classNameProp, ...props }: Props): ReactElement => {
  const [field, meta] = useField<InputProps>(props)
  const errorText = meta.error && meta.touched ? meta.error : ''
  const className = clsx(styles.input, classNameProp)

  return (
    <>
      <input {...field} {...props} className={className} disabled={isDisabled} />
      <InputError errorMessage={errorMessage || errorText} />
    </>
  )
}
