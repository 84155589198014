import clsx from 'clsx'
import React, { ReactElement } from 'react'

import styles from './InputError.module.scss'

type Props = {
  errorMessage: string | undefined
  isVisible?: boolean
  className?: string
}

const InputError = ({ isVisible = true, className: classNameProp, errorMessage }: Props): ReactElement | null => {
  const className = clsx(styles.inputError, classNameProp)

  return (
    <div className={className}>
      {isVisible && errorMessage && (
        <>
          <span className={styles.errorIcon} />
          <span className={styles.errorMessage}>{errorMessage}</span>
        </>
      )}
    </div>
  )
}

export default InputError
