/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios'

import { postAuthApi } from 'utils/http'

import { CreateOrganiznationInput } from './types/organizationServiceTypes'

const ORGANIZATION_SERVICE_URL = process.env.REACT_APP_ORGANIZATION_SERVICE_ENDPOINT

const config = {
  headers: {
    ob_api_client: process.env.REACT_APP_ORGANIZATION_SERVICE_API_CLIENT,
    ob_api_key: process.env.REACT_APP_ORGANIZATION_SERVICE_API_KEY,
  },
}

const composeApiEndpoint = (endpoint: string) => `${ORGANIZATION_SERVICE_URL}/${endpoint}`

const create = ({ userId, organizationName, rootAdminEmail }: CreateOrganiznationInput): Promise<AxiosResponse> =>
  postAuthApi(
    composeApiEndpoint('auth/organizations'),
    {
      name: organizationName,
      rootAdminEmail,
    },
    {
      headers: {
        ...config.headers,
        ob_actor_id: userId,
      },
    }
  ).then((res: AxiosResponse) => res.data)

const OrganizationService = {
  create,
}

export default OrganizationService
