import clsx from 'clsx'
import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import styles from './MenuItem.module.scss'

type Props = {
  accessoryLeft: ReactNode
  label: string
  link: string
  active?: boolean
}

const MenuItem: React.FC<Props> = ({ link, accessoryLeft, label, active }) => {
  const className = clsx(styles.menuItem, { [styles.active]: active })

  return (
    <Link to={link}>
      <div className={className}>
        {accessoryLeft} {label}
      </div>
    </Link>
  )
}

export default MenuItem
