// User can navigate to here any time
export enum PublicRoutes {
  ROOT = '/',
}

// Can only access when not signed in
export enum AuthReverseRoutes {
  SIGN_IN = '/signin',
}

// Can only access if signed in
export enum AuthRoutes {
  DASHBOARD = '/dashboard',
  CREATE_ORGANIZATION = '/dashboard/create-organization',
}
