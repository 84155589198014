import React, { ReactElement } from 'react'
import { connect } from 'react-redux'
import { Route, RouteProps } from 'react-router-dom'

import { RootState } from 'reducers'
import { AuthState } from 'reducers/types'
import { Redirect } from 'ui/components'

import { AuthRoutes } from './consts'

type Props = RouteProps & ReduxProps

const AuthReverseRoute: React.FC<Props> = ({ auth, ...rest }: Props): ReactElement =>
  !auth.isUserSignedIn ? (
    <Route {...rest} />
  ) : (
    <Route {...rest} component={(): React.ReactElement => <Redirect url={AuthRoutes.DASHBOARD} />} />
  )

type ReduxProps = {
  auth: AuthState
}

const mapStateToProps = (state: RootState): ReduxProps => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(AuthReverseRoute)
