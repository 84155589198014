import { readCookie, CookieTypes, createCookie, eraseCookie } from 'utils/cookieHelpers'

type UserSession = {
  sessionId: string
  userId: string
  organizationId: string
}

export const getSessionValues = (): UserSession => {
  const userId = readCookie(CookieTypes.UID_COOKIE)

  if (!userId) {
    return {
      sessionId: '',
      userId: '',
      organizationId: '',
    }
  }

  return {
    sessionId: readCookie(CookieTypes.SESSION_ID_COOKIE) || '',
    userId: readCookie(CookieTypes.UID_COOKIE) || '',
    organizationId: readCookie(CookieTypes.ORGANIZATION_COOKIE) || '',
  }
}

export const createSigninSession = (sessionId: string, userId: string, organizationId: string, days: number): void => {
  createCookie(CookieTypes.UID_COOKIE, userId, days)
  createCookie(CookieTypes.SESSION_ID_COOKIE, sessionId, days)
  createCookie(CookieTypes.ORGANIZATION_COOKIE, organizationId, days)
}

export const isSignedIn = (): boolean => {
  const { sessionId, userId, organizationId } = getSessionValues()
  return (!!sessionId && !!userId) || !!organizationId
}

export const cleanupOnSignout = (): void => {
  eraseCookie(CookieTypes.UID_COOKIE)
  eraseCookie(CookieTypes.SESSION_ID_COOKIE)
  eraseCookie(CookieTypes.ORGANIZATION_COOKIE)
}
