import { FieldAttributes, useField } from 'formik'
import React, { ReactElement, useState } from 'react'
import { Input as SemanticInput, InputProps, Popup } from 'semantic-ui-react'

import { ReactComponent as EyeCrossIcon } from 'assets/images/EyeCrossIcon.svg'
import { ReactComponent as EyeHighlightIcon } from 'assets/images/EyeHighlightIcon.svg'

import InputError from '../InputError'

import styles from './PasswordInput.module.scss'

// eslint-disable-next-line
export type PasswordInputProps = FieldAttributes<any> &
  InputProps & {
    errorMessage?: string
    disablePopup?: boolean
  }

const PasswordInput = ({
  errorMessage,
  disablePopup,
  placeholder = '8 - 16 characters',
  ...props
}: PasswordInputProps): ReactElement => {
  // eslint-disable-next-line
  const [field, meta] = useField<any>(props)
  const errorText = meta.error && meta.touched ? meta.error : ''

  const [showPassword, setShowPassword] = useState(false)
  const type = showPassword ? 'text' : 'password'

  const toggleShowPassword = (): void => {
    setShowPassword(!showPassword)
  }

  const getEyeIcon = (): ReactElement => (showPassword ? <EyeCrossIcon /> : <EyeHighlightIcon />)

  const action = {
    icon: getEyeIcon(),
    type: 'button',
    onClick: (): void => {
      toggleShowPassword()
    },
  }

  const content = (): ReactElement => (
    <>
      <div>Password should have: </div>
      <div>- 8-16 characters</div>
      <div>- At least one number</div>
      <div>- At least one upper and one lower case letter</div>
      <div>- At least one special character: {`! @ # $ % ^ & ( ( )`}</div>
    </>
  )

  return (
    <div className={styles.passwordInputContainer}>
      <Popup
        content={content}
        position='right center'
        disabled={disablePopup}
        on='focus'
        size='large'
        inverted
        trigger={
          <SemanticInput
            {...field}
            action={action}
            type={type}
            className={styles.passwordInput}
            placeholder={placeholder}
          />
        }
      />
      <InputError errorMessage={errorText || errorMessage} />
    </div>
  )
}

export default PasswordInput
