import { trim } from 'lodash'

import { SignedInAction, SignOutAction } from 'actions/types'
import { SIGNED_IN, SIGN_OUT, UPDATE_PROFILE } from 'actions/user'
import { defaultCookieExpirationDate } from 'utils/cookieHelpers'
import { isSignedIn, createSigninSession, cleanupOnSignout } from 'utils/signinHelpers'

import { AuthState } from './types'

const initialState: AuthState = {
  user: {
    id: '',
    name: '',
    email: '',
    organizationId: '',
    organizationName: '',
    role: {
      _id: '',
      name: '',
      active: true,
    },
  },
  sessionId: '',
  isUserSignedIn: isSignedIn(),
}

const authReducer = (state = initialState, action: SignedInAction | SignOutAction): AuthState => {
  switch (action.type) {
    case SIGNED_IN: {
      createSigninSession(
        (action as SignedInAction).data.sessionId,
        (action as SignedInAction).data.user.id,
        (action as SignedInAction).data.user.organizationId,
        defaultCookieExpirationDate
      )

      const { user, sessionId } = (action as SignedInAction).data

      user.name = trim(`${user.firstName} ${user.lastName}`)

      return {
        ...state,
        user: { ...user },
        sessionId,
        isUserSignedIn: true,
      }
    }

    case UPDATE_PROFILE: {
      return {
        ...state,
        user: {
          ...state.user,
          name: action.payload?.name || '',
          organizationName: action.payload?.organizationName || '',
        },
      }
    }

    case SIGN_OUT: {
      cleanupOnSignout()
      return { ...initialState, isUserSignedIn: false }
    }

    default:
      return state
  }
}

export default authReducer
