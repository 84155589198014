import { Formik } from 'formik'
import React, { ReactElement, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Dispatch } from 'redux'
import { User } from 'services/types/userServiceTypes'
import UserService from 'services/userService'
import * as yup from 'yup'

import { SignedInAction } from 'actions/types'
import { signedIn } from 'actions/user'
import { RootState } from 'reducers'
import { Button, Form, Input } from 'ui/components'
import FormField from 'ui/components/FormField'
import InputError from 'ui/components/InputError'
import PasswordInput from 'ui/components/PasswordInput'
import AuthLayout from 'ui/pages/common/AuthLayout'
import { getFirstValueOfParam, getQueryParams } from 'utils/queryStringHelpers'
import { showErrorToast, showSuccessToast } from 'utils/toastUtil'

import styles from './SignIn.module.scss'

const initialValues = { email: '', password: '' }

// Admin Role ID
// 611a7668a6458a3d2cf3f183

const validationSchema = yup.object().shape({
  email: yup.string().email('email address is invalid').required(),
  password: yup.string().required(),
})

type Props = {
  signedIn: (user: User, sessionId: string) => SignedInAction
  location: Location
}

const SignIn = ({ signedIn, location }: Props): ReactElement => {
  const history = useHistory()
  const [loginFailed, setLoginFailed] = useState(false)
  const { prompt } = getQueryParams(location)
  const promptValue = getFirstValueOfParam(prompt)

  useEffect(() => {
    if (!!promptValue && promptValue === 'password-reset') {
      showSuccessToast({
        title: 'Your password has been successfully changed!',
        message: 'Please log in with your new password.',
      })
    }
  }, [])

  return (
    <AuthLayout>
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async ({ email, password }, { setSubmitting }): Promise<void | ReactElement> => {
          try {
            setLoginFailed(false)
            setSubmitting(true)

            const response = await UserService.signIn({ identifier: email, password })

            // Temporary: if user does not have admin access, reject
            // eslint-disable-next-line
            if ((response?.user as any)?.roleId !== '611a7668a6458a3d2cf3f183') {
              showErrorToast({ title: 'You do not have permissions to access this page.' })
              throw new Error('Permission denied')
            }

            const { user, sessionId } = response
            signedIn(user, sessionId)

            return history.push('/dashboard')
          } catch {
            setLoginFailed(true)
          } finally {
            setSubmitting(false)
          }
        }}
      >
        {({ errors, isSubmitting, handleSubmit }): ReactElement => (
          <Form onSubmit={handleSubmit} className={styles.signinForm}>
            <h1 className={styles.formTitle}>Welcome! Please log in</h1>
            <FormField errorMessage={errors.email}>
              <label>Email</label>
              <Input type='text' name='email' />
            </FormField>
            <FormField errorMessage={errors.password}>
              <label>Password</label>
              <PasswordInput name='password' disablePopup={true} placeholder='' />
            </FormField>
            <FormField>
              <InputError errorMessage='incorrect login information' isVisible={loginFailed} />
            </FormField>
            <Button type='submit' style='primary' isLoading={isSubmitting} disabled={isSubmitting} fluid>
              Log In
            </Button>
          </Form>
        )}
      </Formik>
    </AuthLayout>
  )
}

type ReduxProps = {
  language: string
}

const mapStateToProps = (state: RootState): ReduxProps => ({
  language: state.language,
})

type DispatchProps = {
  signedIn: (user: User, sessionId: string) => SignedInAction
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  signedIn: (user: User, sessionId: string): SignedInAction => dispatch(signedIn(user, sessionId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
