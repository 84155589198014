import { trim } from 'lodash'
import { User } from 'services/types/userServiceTypes'

import { SignedInAction, UpdateProfileAction, SignOutAction } from './types'

export const SIGNED_IN = 'SIGNED_IN'
export const SIGN_OUT = 'SIGN_OUT'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'

export const signedIn = (user: User, sessionId: string): SignedInAction => ({
  type: SIGNED_IN,
  data: { user, sessionId },
})

export const signOut = (): SignOutAction => ({ type: SIGN_OUT })

export const updateProfile = ({ firstName = '', lastName = '', organizationName = '' }: User): UpdateProfileAction => ({
  type: UPDATE_PROFILE,
  payload: {
    name: trim(`${firstName} ${lastName}`),
    organizationName: organizationName,
  },
})
