import clsx from 'clsx'
import React, { ReactElement } from 'react'
import { FormField as SemanticFormField, FormFieldProps } from 'semantic-ui-react'

import styles from './FormField.module.scss'

type Props = FormFieldProps & {
  errorMessage?: string
  block?: boolean
}

export const FormField = ({ errorMessage, className: classNameProp, block = true, children }: Props): ReactElement => {
  const className = clsx(
    styles.formField,
    {
      [styles.block]: block,
      [styles.hasError]: errorMessage,
    },
    classNameProp
  )

  return <SemanticFormField className={className}>{children}</SemanticFormField>
}
