import React from 'react'
import { ReactElement } from 'react'
import { Link } from 'react-router-dom'

import styles from './AuthLayout.module.scss'

type Props = {
  children: ReactElement
  showFooter?: boolean
}

const AuthLayout = ({ children, showFooter = false }: Props): ReactElement => (
  <div className={styles.authLayout}>
    <div className={styles.left}>
      <div className={styles.banner} />
    </div>
    <div className={styles.right}>
      <div className={styles.main}>{children}</div>
      {showFooter && (
        <div className={styles.footer}>
          <Link to='/policy/terms-and-conditions' className={styles.footerLink}>
            Terms of Service
          </Link>
          <Link to='/policy/privacy' className={styles.footerLink}>
            Privacy Policy
          </Link>
        </div>
      )}
    </div>
  </div>
)

export default AuthLayout
