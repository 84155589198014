/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios'

import { getAuthApi, postAuthApi, putAuthApi, patchAuthApi } from 'utils/http'

import { GetUsersForOrganizationResponse } from './types/userServiceTypes'
import {
  SignInResponse,
  SignInInput,
  SignUpInput,
  SignOutInput,
  SignUpResponse,
  UpdateUserProfileInput,
  User,
  InviteUserInput,
  GetUsersForOrganizationInput,
} from './types/userServiceTypes'

const USER_SERVICE_URL = process.env.REACT_APP_USER_SERVICE_ENDPOINT

const config = {
  headers: {
    ob_api_client: process.env.REACT_APP_USER_SERVICE_API_CLIENT,
    ob_api_key: process.env.REACT_APP_USER_SERVICE_API_KEY,
  },
}

const composeApiEndpoint = (endpoint: string) => `${USER_SERVICE_URL}/${endpoint}`

const inviteUserByEmail = ({ userId, email, organizationId, roleId }: InviteUserInput): Promise<AxiosResponse> =>
  postAuthApi(
    composeApiEndpoint('auth/invitation'),
    {
      identifier: email,
      roleId,
      organizationId,
      sentBy: userId,
    },
    {
      headers: {
        ...config.headers,
        ob_actor_id: userId,
      },
    }
  ).then((res: AxiosResponse) => res.data)

const signIn = (body: SignInInput): Promise<SignInResponse> =>
  postAuthApi(composeApiEndpoint('auth/signin'), body, config).then((res: AxiosResponse<SignInResponse>) => res.data)

const signUp = (body: SignUpInput) =>
  postAuthApi(composeApiEndpoint('auth/signup'), body, config).then((res: AxiosResponse<SignUpResponse>) => res.data)

const signOut = (body: SignOutInput) =>
  postAuthApi(composeApiEndpoint('auth/signout'), body, config).then((res: AxiosResponse<SignInResponse>) => res.data)

const updateUserProfile = ({ userId, ...attrs }: UpdateUserProfileInput) =>
  putAuthApi(
    composeApiEndpoint(`auth/users/${userId}/profile`),
    {
      ...attrs,
    },
    {
      headers: {
        ...config.headers,
        ob_actor_id: userId,
      },
    }
  ).then((res: AxiosResponse<User>) => res.data)

const listUsersForOrganization = ({
  userId,
  pageNumber,
  pageSize,
}: GetUsersForOrganizationInput): Promise<GetUsersForOrganizationResponse> =>
  getAuthApi(composeApiEndpoint(`auth/users?pageIndex=${pageNumber - 1}&pageSize=${pageSize}`), {
    headers: {
      ...config.headers,
      ob_actor_id: userId,
    },
  }).then((res: AxiosResponse<GetUsersForOrganizationResponse>) => res.data)

const deactivateUser = (actorId: string, user?: User) => {
  if (user) {
    patchAuthApi(
      composeApiEndpoint(`auth/users/${user.id}/active`),
      {
        active: false,
      },
      {
        headers: {
          ...config.headers,
          ob_actor_id: actorId,
        },
      }
    ).then(res => res.data)
  }
}

const UserService = {
  inviteUserByEmail,
  signIn,
  signUp,
  signOut,
  updateUserProfile,
  listUsersForOrganization,
  deactivateUser,
}

export default UserService
